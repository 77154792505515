<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">รายการซื้อยาและเวชภัณฑ์</h4>
      </div>

      <div class="d-flex justify-end">
        <v-btn
          :loading="loader"
          :disabled="loader"
          color="blue-grey"
          class="my-3 white--text"
          @click="goToAddStockMore()"
        >
          <v-icon
            left
            dark
          >
            mdi-plus-circle
          </v-icon>
          เพิ่มรายการซื้อยาและเวชภัณฑ์
        </v-btn>
      </div>

      <v-row>
        <v-col cols="12" sm="12" md="12" lg="3">
          <div>
            <v-icon class="mr-3">{{product_type === 'drug' ? 'mdi-pill' : 'mdi-needle'}}</v-icon>
            <span class="title-drudsandmedical-add-stock-incard">ยา</span>
          </div>
          <v-row class="mt-1">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อยา:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">รหัสยา:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_code}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">กลุ่ม:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_group_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ประเภท:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_category}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ยี่ห้อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_brand}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ผู้จำหน่าย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_dealer}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">รอบสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_stock_cycle}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ราคาซื้อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_cost_price}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">วันที่ซื้อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_buy_date}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">วันหมดอายุ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_best_before}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">จำนวน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_buy_number}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">หน่วยนับ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_unit_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">จำนวนแจ้งเตือน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_alert_remain}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">คงเหลือ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <!-- <span>{{product_remain}}</span> -->
              <div class="d-flex flex-row align-center">
                <span>{{product_remain}}</span>
                <div
                  v-if="product_remain === 0"
                  class="d-flex flex-column align-center justify-center btn-warning-outstock px-3 py-1 ml-3"
                >
                  <v-icon color="#FFF" size="18">mdi-alert-circle</v-icon>
                  <span class="label-warning-outstock">หมดแล้ว</span>
                </div>
                <div
                  v-else-if="product_remain <= product_alert_remain"
                  class="d-flex flex-column align-center justify-center btn-warning-nearoutstock px-3 py-1 ml-3"
                >
                  <v-icon color="#FFC107" size="18">mdi-alert-circle</v-icon>
                  <span class="label-warning-nearoutstock">ใกล้หมด</span>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{product_status}}</span>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="9">
          <v-data-table
            :headers="headers"
            :items="data"
            :page="page"
            :options="pagination"
          >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">{{index + 1}}</td>
              <td class="text-center">{{item.product_stock_cycle}}</td>
              <td class="text-center">{{item.product_dealer}}</td>
              <td class="text-end">{{item.product_cost_price_per_unit}}</td>
              <td class="text-center">{{item.product_buy_date}}</td>
              <td class="text-center">{{item.product_best_before}}</td>
              <td class="text-end">{{item.product_buy_number}}</td>
              <td class="text-end">{{item.product_sell_date}}</td>
              <td class="text-end">{{item.product_remain}}</td>
              <td class="text-center">{{item.product_update_date}}</td>
            </tr>
          </template>

          <template v-slot:no-data>
            <!-- <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn> -->
          </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      page: 1,
      limit: 10,
      pagination: {
        page: 1,
        itemsPerPage: 10
      },
      filter_search: '',
      product_type: '',
      product_code: '',
      product_name: '',
      product_group_name: '',
      product_category: '',
      product_brand: '',
      product_dealer: '',
      product_stock_cycle: '',
      product_cost_price: 0,
      product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
      product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
      product_buy_number: 0,
      product_sell_price: 0,
      product_remain: 0,
      product_alert_remain: 0,
      product_unit_name: '',
      product_status: 'inactive',
      product_status_bool: false,
      data: [
        {
          _id: '0001',
          product_stock_cycle: 'L00051',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 10000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 5000,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0002',
          product_stock_cycle: 'L00050',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 5000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 0,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0003',
          product_stock_cycle: 'L00049',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 50000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 10000,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0004',
          product_stock_cycle: 'L00046',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 10000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 0,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0005',
          product_stock_cycle: 'L00045',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 10000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 0,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0006',
          product_stock_cycle: 'L00040',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 5000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 200,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0007',
          product_stock_cycle: 'L00040',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 5000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 200,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0008',
          product_stock_cycle: 'L00040',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 5000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 200,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0009',
          product_stock_cycle: 'L00040',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 5000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 200,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0010',
          product_stock_cycle: 'L00040',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 5000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 200,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        },
        {
          _id: '0011',
          product_stock_cycle: 'L00040',
          product_dealer: 'ไม่ระบุ',
          product_cost_price_per_unit: 150,
          product_buy_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_best_before: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_buy_number: 5000,
          product_sell_date: moment().add(543, 'year').format('DD/MM/YYYY'),
          product_remain: 200,
          product_update_date: moment().add(543, 'year').format('DD/MM/YYYY')
        }
      ]
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          value: 'sn',
          sortable: false
        }, {
          text: 'รอบสต๊อก',
          align: 'center',
          value: 'product_stock_cycle',
          sortable: false
        }, {
          text: 'ผู้จำหน่าย',
          align: 'center',
          value: 'product_dealer',
          sortable: false
        }, {
          text: 'ราคาที่ซื้อ/ต่อหน่วย',
          align: 'center',
          value: 'product_cost_price_per_unit',
          sortable: false
        }, {
          text: 'วันที่ซื้อ',
          align: 'center',
          value: 'product_buy_date',
          sortable: false
        }, {
          text: 'วันหมดอายุ',
          align: 'center',
          value: 'product_best_before',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'product_buy_number',
          sortable: false
        }, {
          text: 'ขายออก',
          align: 'center',
          value: 'product_sell_date',
          sortable: false
        }, {
          text: 'คงเหลือ',
          align: 'center',
          value: 'product_remain',
          sortable: false
        }, {
          text: 'วันที่อัพเดท',
          align: 'center',
          value: 'product_update_date',
          sortable: false
        }
      ]
    }
  },
  watch: {
    pagination (val) {
      const self = this
      console.log(self.pagination, val)
    }
  },
  created () {
    const self = this
    // console.log(self.$route.params)
    self.product_type = self.$route.params.product_type
    self.product_name = self.$route.params.product_name
    self.product_code = self.$route.params.product_code
    self.product_group_name = self.$route.params.product_group_name
    self.product_category = self.$route.params.product_category
    self.product_brand = self.$route.params.product_brand
    self.product_dealer = self.$route.params.product_dealer
    self.product_stock_cycle = self.$route.params.product_stock_cycle
    self.product_cost_price = self.$route.params.product_cost_price
    self.product_unit_name = self.$route.params.product_unit_name
    self.product_alert_remain = self.$route.params.product_alert_remain
    self.product_remain = self.$route.params.product_remain
    self.product_status = self.$route.params.product_status_bool ? 'เปิดใช้งาน' : 'ปิดใช้งาน'
    self.product_status_bool = self.$route.params.product_status_bool
  },
  methods: {
    goToAddStockMore () {
      const self = this
      self.loader = 'loader'
      setTimeout(() => {
        self.$router.push({ name: 'DrugsAndMedicalAddToStockAgainScreen', params: self.$route.params })
      }, 200)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    }
  }
}
</script>

<style scoped>
@import '../../../styles/DrugsAndMedical_AddStock.css';
</style>
